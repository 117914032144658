<template lang="pug">
.step-field-mapping
  .title {{ $t('integrationFlow.integrationModal.general.fieldMappingAltTitle', { type: 'HighLevel' }) }}
  .d-flex.fields-wrapper.w-100
    om-toast.mb-6(v-if="!validByAlert.fields" color="danger" :closable="false" type="side") {{ $t('integrationFlow.integrationModal.general.checkFields', { integration: 'HighLevel' }) }}
    .d-flex.fields-title
      .om-fields-header {{ $t('integrationFlow.integrationModal.general.omInputFields') }}
      .arrow-block
      .integration-fields-header {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'HighLevel' }) }}
    .d-flex.fields(v-for="(binding, index) in formBindings")
      binding-row.w-100.justify-content-between(
        :isNewFlow="true"
        :binding="binding"
        :index="index"
        :fields="fields"
        :type="settings.type"
        :hasError="bindingRowHasError(binding)"
      )
  .d-flex.fields-title.mb-2
    .om-fields-header {{ $t('integrationFlow.highLevel.tags') }}
  tag-multiselect(
    v-if="tagBinding"
    :binding="tagBinding"
    :type="settings.type"
    :options="tagOptions"
  )
  .binding-error-message(v-if="missingRequiredFields.length") {{ bindingErrorMessage }}
  .d-flex.additional-data
    .d-flex.additional-data-row
      label.d-flex(for="additionalDataCheckbox")
        input#additionalDataCheckbox(type="checkbox" v-model="checkboxValue")
        .additional-data-text {{ $t('integrationFlow.integrationModal.general.additionalData') }}
    .d-flex.additional-data-row
      .additional-data-helper {{ $t('integrationFlow.integrationModal.general.additionalDataHelper') }}
  transition(name="fade")
    .d-flex.other-fields(v-if="checkboxValue")
      .d-flex.fields-wrapper.w-100
        .d-flex.fields-title
          .om-fields-header {{ $t('integrationFlow.integrationModal.general.customValue') }}
          .arrow-block
          .integration-fields-header {{ $t('integrationFlow.integrationModal.general.integrationFields', { type: 'HighLevel' }) }}
        .d-flex.fields(v-for="(binding, index) in campaignDataBindings")
          .om-campaign-additional-fields.w-100
            binding-row.w-100.justify-content-between(
              :binding="binding"
              :index="index"
              :fields="fields"
              :type="settings.type"
              :isNewFlow="true"
            )
        .d-flex.fields(v-if="staticBindings.length > 0" v-for="(binding, index) in staticBindings")
          .om-campaign-static-fields.w-100(v-if="binding.externalType !== '__TAG__'")
            binding-row.w-100.justify-content-between(
              :binding="binding"
              :index="index"
              :fields="fields"
              :isNewFlow="true"
              :type="settings.type"
              @removeStaticRow="deleteStaticField(index)"
            )
        .add-fields
          .d-flex.cursor-pointer(@click="addStaticField")
            om-button(icon="plusCircle" iconSize="1.715em" iconOnly style="color: #ed5a29")
            .font-size-0--875.font-weight-semibold.line-height-1.align-self-center {{ $t('integrationFlow.integrationModal.general.addCustomValue') }}
</template>
<script>
  import { UilArrowRight } from '@iconscout/vue-unicons';
  import bindingMixin from '@/mixins/integration/binding';
  import BindingRow from '@/components/IntegrationBindings/Row';
  import { IntegrationService } from '@/services/integrations/integrationService';
  import { getConfigForIntegration } from '@/../../../libraries/integrations/src';
  import TagMultiselect from '@/components/IntegrationBindings/TagMultiselect';

  export default {
    components: {
      UilArrowRight,
      BindingRow,
      TagMultiselect,
    },

    mixins: [bindingMixin],

    props: {
      validByAlert: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        fields: [],
        tagOptions: [],
      };
    },

    computed: {
      tagBinding() {
        return this.staticBindings.find((binding) => binding.externalType === '__TAG__');
      },
      tags() {
        const tagFields = getConfigForIntegration(this.settings.type)?.tagFields || [];

        if (tagFields && tagFields.length) {
          return tagFields;
        }
        return null;
      },
    },

    async mounted() {
      this.$on('isBindingModified', (value) => this.$emit('isModified', value));

      const integrationService = new IntegrationService(this.$apollo);
      this.loading = true;

      try {
        const { fields, tags } = await integrationService.fetchIntegrationData(
          this.settings.type,
          this.settings.id,
        );

        this.tagOptions = tags.map(({ name }) => name);

        this.fields = [
          {
            id: 'firstName',
            name: 'firstName',
          },
          {
            id: 'lastName',
            name: 'lastName',
          },
          {
            id: 'name',
            name: 'name',
          },
          {
            id: 'email',
            name: 'email',
          },
          {
            id: 'phone',
            name: 'phone',
          },
          {
            id: 'source',
            name: 'source',
          },
          {
            id: 'tag',
            name: this.$t('integrationFlow.highLevel.tag'),
          },
          ...fields,
        ];
      } catch (e) {
        this.errorNotification(e.message);
      }
      this.addTagsAsStatic();
      this.loading = false;
    },

    methods: {
      addTagsAsStatic() {
        this.tags.forEach((fieldId) => {
          const bind = this.settings.bindings.find((item) => {
            const { externalId, externalType } = item;
            return externalType === '__TAG__' && externalId === fieldId;
          });

          if (!bind) {
            const binding = this.getStaticField();
            binding.externalType = '__TAG__';
            binding.externalId = fieldId;
            this.settings.bindings.push(binding);
          }
        });
      },
      errorNotification(message) {
        this.$notify({
          type: 'error',
          text: message,
        });
        setTimeout(() => {
          this.$bus.$emit('integration-show-first-step');
        }, 2000);
      },
    },
  };
</script>
